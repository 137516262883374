.App {
    text-align: center;
    display: flex;
    justify-content: center;
    background-color: #f5f7ff;
    background-color: #f4f4f4;
    height: 100%;
    min-height: 100vh;
    width: 100%;

    .MuiGrid-container {
        margin-left: 0px;
    }

    .pim-component {
        // Same transition as the drawer
        transition: all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    }

    &.toggled {
        .pim-component {
            width: calc(100% - 500px) !important;
            transform: translateX(500px);
        }
    }

    .temp {
        text-align: center;
        display: flex;
        flex-direction: row;
        justify-content: center;

        .formRow {
            width: 500px;
            margin: 15px 30px;

            .formGroup {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: start;
                align-items: start;
                margin: 20px 0;

                label {
                    font-size: 15px;
                    margin-bottom: 5px;
                    text-align: start;
                }

                input {
                    &:not([type="checkbox"]) {
                        width: 100%;
                    }

                    &:required {
                        background-color: lightblue;
                    }
                }
            }
        }
    }

    div[id^="simple-tabpanel-"] {
        & > div {
            border: none;
            padding: 0px !important;
        }
    }
}

.sidebar-open {
    width: calc(100% - 300px);
    grid-template-columns: 1fr 300px;
}

.MuiPaper-outlined,
.MuiAccordionDetails-root {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;

    .MuiAutocomplete {
        &-root {
            width: 100%;
        }

        &-multiple {
            > .MuiFormControl-root {
                transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
                &:hover,
                &:has(.Mui-focused) {
                    background-color: rgba(0, 0, 0, 0.09);
                }
            }

            .MuiInputBase-root {
                // padding-top: 24px;
                margin-top: 10px;
                padding-bottom: 7px;

                &:hover,
                &.Mui-focused {
                    background-color: unset;
                }
            }
        }
    }

    .ql-snow {
        .ql-editor {
            background-color: #f4f4f4 !important;
        }
    }

    .MuiFilledInput-root {
        background-color: rgba(0, 0, 0, 0);
    }

    h2 {
        font-size: 15px;
        text-align: left !important;
    }

    .date-input {
        .MuiInputLabel-animated {
            transform: translate(12px, 7px) scale(0.75);
        }
    }
}

.MuiAccordionDetails-root {
    justify-content: center;
}

// .ProductForm {
//     // .MuiBox-root {
//     //     display: flex;
//     //     flex-wrap: wrap;
//     //     justify-content: center;
//     // }
// }

.cartouches-crud {
    .MuiPaper-outlined,
    .MuiAccordionDetails-root {
        display: block;
    }

    .MuiBox-root {
        display: flex;
        flex-wrap: wrap;

        div {
            flex: 50%;
        }
    }
}

.createForm {
    .MuiPaper-outlined {
        justify-content: center;
    }
}

.productForm {
    width: 100%;
}

.delete-modal {
    .MuiBackdrop-root {
        background-color: rgba(0, 0, 0, 0.1) !important;
    }

    .MuiBox-root {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
}

.force-colorless {
    label,
    h2 {
        color: #000000de !important;
    }

    div.Mui-error {
        &::after {
            border-bottom-color: #989898 !important;
        }

        input {
            color: #000000de !important;
        }
    }
}
