.searchBox {
    position: relative;
    min-width: 600px;
    .results {
        position: absolute;
        top: 56px;
        left: 0;
        max-height: 300px;
        background-color: #fff;
        overflow-y: scroll;
        min-width: 600px;
        .result {
            padding: 10px;
            color: black;
            transition: all 0.3s ease;
            background-color: #fff;
            border-bottom: 1px solid #f4f4f4;
            cursor: pointer;
            &:hover {
                background-color: #f4f4f4;
            }
        }
    }
}
