.ProductForm {
    width: 80%;
    margin: 30px auto 0;
    padding-bottom: 32px;
    .download-button {
        position: absolute;
        right: 65px;
    }

    .catalog-breadcrumbs {
        > .MuiBreadcrumbs-ol {
            justify-content: center;
        }
    }

    .MuiAccordionSummary-content {
        align-items: center;
    }

    .formRow {
        width: 100%;
        .grid-0 {
            span {
                width: 100%;
                height: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .header-tools {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row !important;
        gap: 10px;
    }

    .tab {
        img {
            filter: invert(48%) sepia(1%) saturate(0%) hue-rotate(178deg) brightness(90%) contrast(81%);
            transition: all 0.2s ease;
            &.active-custom-icon {
                filter: invert(27%) sepia(94%) saturate(944%) hue-rotate(218deg) brightness(84%) contrast(91%);
            }
        }
    }
    .wrapper-image {
        position: relative;
        .pin-nb-medias {
            position: absolute;
            width: 20px;
            height: 20px;
            background-color: #4b49ac;
            border-radius: 50%;
            color: #fff;
            top: -8px;
            right: -8px;
            font-size: 14px;
            font-weight: bold;
        }
    }

    .renderedHtml {
        p {
            margin: 0;
            text-align: left;
            color: #000000;
        }
    }

    .product-imageHD {
        object-fit: cover;
    }
}
