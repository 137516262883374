.ProductForm {
    width: 80%;
    margin: 0 auto;
    padding-bottom: 32px;

    .header-tools {
        display: flex;
        flex-direction: column;
        row-gap: 0;
    }

    &.ProductFormHeader {
        margin-top: 0;
        position: sticky;
        top: 78px;
        z-index: 55;
        .MuiBox-root {
            align-items: center;
            justify-content: center;
        }
    }
}
