.Catalog {
    margin-top: 14px;
    width: 100%;
    padding: 50px;
    .productCard {
        a {
            padding: 20px !important;
            height: 100%;
            width: 100%;
        }
    }

    .MuiTableCell-root {
        text-align: center;
    }
}

.centerItem {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
