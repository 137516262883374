.sidebar {
    position: relative;
    & > div {
        padding: 20px;
    }
    .buttonSidebar {
        margin: 10px 0;
        padding: 15px;
        width: 100%;
        justify-content: space-between;
        border-radius: 10px;

        &.selected {
            background-color: #4c49ac;
            color: #fff;
        }
    }

    & > div:first-of-type {
        margin-top: 60px;
    }

    .drawer-drag {
        position: absolute;
        height: calc(100vh - 60px);
        width: 30px;
        background-color: #fff;
        right: -15px;
        top: 0;
        cursor: ew-resize;
    }
}
