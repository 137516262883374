.tree-item {
    .category-tree-item {
        position: relative;

        &.current > div {
            background-color: #d9d9d9;
        }
    }
    // .category-tree-add-category,
    // .category-tree-add-product {
    //     opacity: 0;
    //     position: absolute;
    //     right: 15px;
    //     transition: all 0.3s ease;
    //     margin-top: -33px;
    // }
    // .category-tree-add-product {
    //     right: 50px;
    // }
    // :hover {
    //      .category-tree-add-category,
    //      .category-tree-add-product {
    //         opacity: 1;
    //     }
    // }
}
.MuiTreeItem-content {
    padding: 4px 8px !important;
    background-color: #f1f0f0;
    transition: all 0.3s ease;
    overflow: visible;

    &::after {
        content: "";
        width: 1px;
        height: 100%;
        background-color: #bfb9b9;
        position: absolute;
        left: -2px;
        top: 0;
    }
    .MuiTreeItem-label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .icons {
            width: max-content;
            display: flex;
            position: absolute;
            top: 50%;
            right: 0;
            opacity: 0;
            transform: translateY(-50%);
            transition: all 0.4s ease;
        }

        .category-tree-add-category,
        .category-tree-add-product {
            margin: 0;
            padding: 4px;
        }
    }

    &:hover {
        background-color: #e9e8e8 !important;
        .icons {
            opacity: 1;
        }
    }
}

.tree-wrapper {
    background-color: #f1f0f0;
    overflow: hidden;
    overflow-y: auto;
}
