.Home {
    // margin-left: 100px !important;
    margin-top: 100px !important;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.center {
    align-items: center;
    justify-content: center;
    margin-top: 0px !important;
    a {
        text-decoration: none;
        color: white;
        font-size: 18px;
    }
    button:hover {
        background-color: #1465b5;
        transition: 0.5s;
    }
}
